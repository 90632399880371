import React from 'react';
import '../Splash/splash.css';
import LogoGrado from '../../assets/images/logoGrado.png';

export default function Splash() {
    return (
      <div className="splashConatiner">
            {/* <div>Splash</div> */}
            <img alt="" src={LogoGrado} style={{width: '15%'}} />
      </div>
  )
}
