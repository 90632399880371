import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from '../screen/Home';
import Splash from '../Components/Splash';

const AppRoutes = () => (
    <BrowserRouter>
        <Routes>
            {/* Static route for Splash at the root */}
            <Route path="/" element={<Home />} />
            
            {/* Explicit route for Splash */}
            <Route path="/splash" element={<Splash />} />
            
            {/* Dynamic route with a parameter */}
            <Route path="/:id" element={<Home />} />
            
            {/* Fallback route */}
            {/* <Route path="*" element={<Home />} /> */}
        </Routes>
    </BrowserRouter>
);

export default AppRoutes;
