import React from 'react'
import './header.css'
import { Button } from 'react-bootstrap'
export default function Header() {
    return (
        <div className="headerContainer">
            <div className="ButtonContainer">
                <Button>Login</Button>
            </div>
        </div>
    )
}
