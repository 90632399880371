import React, { useEffect, useState } from 'react';
import './home.css';
import Header from '../../Components/Header';
import { Accordion, Button, Card, Col, Row } from 'react-bootstrap';
import VinylImg from '../../assets/images/vinylimg.png'
import Ratting from '../../assets/images/rattingdisk.png'
import G from '../../assets/images/g.png';
import R from '../../assets/images/r.png';
import CD from '../../assets/images/cd.png';
import DownArrow from '../../assets/images/downarrow.png'
import Heart from '../../assets/images/heart.png'
import Splash from '../../Components/Splash';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const TracklistItem = ({ track, index }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <Card className="text-light border-0 mb-1 accordionBG" onClick={() => setIsOpen(!isOpen)}>
            <Card.Header className="d-flex justify-content-between align-items-center py-2 px-3" style={{ backgroundColor: 'rgba(255,255,255,0.05)' }}>
                <div>
                    <span className="me-2 accordionFontSize">A{index + 1}</span>
                    <span className="accordionFontSize">{track.title}</span>
                </div>
                <div className="d-flex align-items-center">
                    <span className="me-3 accordionFontSize">{track.duration}</span>
                    <Button
                        variant="link"
                        className="p-0 text-light"

                    >
                        {isOpen ? <img alt="" src={DownArrow} style={{ height: '9px', transform: 'rotate(180deg)' }} /> : <img alt="" src={DownArrow} style={{ height: '9px' }} />}
                    </Button>
                </div>
            </Card.Header>
            <Accordion.Collapse in={isOpen}>
                <Card.Body className="py-2 px-3" style={{ backgroundColor: 'rgba(255,255,255,0.02)' }}>
                    {Object.entries(track.details).map(([key, value]) => (
                        <p key={key} className="mb-1 small">
                            <span className="accordionFontSize">{key}: </span>
                            <span className='accordionFontSize'>{value}</span>
                        </p>
                    ))}
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    );
};

export default function Home() {
    const { id } = useParams();
    const [splashScreen, setSplashScreen] = useState(true);
    const [data, setData] = useState(null);
    useEffect(() => {
        setTimeout(() => {
            setSplashScreen(false);
        }, 2500)
    })
    const tracks = [
        {
            title: 'Lorem Ipsum',
            duration: '3:48',
            details: {
                'Engineer': 'Mark Ranking',
                'Mixed By': 'Tom Elmhirst',
                'Mixed by (Assisted By)': 'Daniel Parry',
                'Produced': 'Paul Epworth',
                'Written-By': 'Adele Adkins, Paul Epworth'
            }
        },
        {
            title: 'Lorem Ipsum',
            duration: '5:03',
            details: {
                'Engineer': 'Mark Ranking',
                'Mixed By': 'Tom Elmhirst',
                'Mixed by (Assisted By)': 'Daniel Parry',
                'Produced': 'Paul Epworth',
                'Written-By': 'Adele Adkins, Paul Epworth'
            }
        },
        // ... (add more tracks)
    ];
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`https://api.hireacoder.in/v1/public/${id}`);
                setData(response.data);
            } catch (err) {
                console.log(err.message)
            }
        };
        fetchData();
    }, []);
    return (
        <>
            {splashScreen ? (<Splash />)
                :
                <>
                    <Header />
                    <div className="mainContainer">
                        <div className="containerNew">
                            <Row>
                                <Col xs={12} lg={12} className='upperSection spacingTop'>
                                    <div>
                                        <Row>
                                            <Col xs={12} lg={6}>
                                                <div className='contImg'>
                                                    <img alt="" src={VinylImg} className='vinlimg' />
                                                    <div style={{ marginBottom: '30px' }}>
                                                        <h4 className='vinylTitle'>{data?.vinyl?.name ?? 'Unknown'}</h4>
                                                        <p style={{  color: 'white', marginBottom: 15 }}></p>
                                                        <div className='vinylratingContainer'>
                                                            <div className='containerRating'>
                                                                <img alt="" src={Ratting} className='rating' />
                                                                <span>{data?.vinyl?.description?.vinyl_condition?.overall_grade ?? 'Poor'}</span>
                                                            </div>
                                                            <div className='vinylPercent'>
                                                                {data?.vinyl?.description?.vinyl_condition
                                                                    ?.numerical_score ?? 0}%
                                                            </div>
                                                        </div>
                                                        <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginTop: 10 }}>
                                                            <div className='dropdownContainer'>
                                                                <span className='SingleChar'>  <img alt="" src={G} className='imgSingle' /></span><span className='Vg'>Very Good</span> <img alt="" src={DownArrow} style={{ height: '9px' }} />
                                                            </div>
                                                            <div className='dropdownContainer'>
                                                                <span className='SingleChar'>  <img alt="" src={R} className='imgSingle' /></span><span className='Vg'>Excellent</span> <img alt="" src={DownArrow} style={{ height: '9px' }} />
                                                            </div>
                                                        </div>
                                                        <div className='nameProfile'>
                                                            <div className='firstCharacter'>U</div>
                                                            <span style={{ color: 'white' }}> First Name Last Name</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xs={12} lg={6} className='btnSection'>
                                                {/* <div> */}
                                                <div style={{ position: 'relative' }}>
                                                    <img alt="" src={CD} style={{ height: 100 }} />

                                                    <div style={{ position: 'absolute', bottom: 5, width: 113, border: '4px solid gray', right: -6, padding: 2 }}>
                                                        <div style={{ border: '2px solid gray' }}>
                                                            <div className='timeContainer'>
                                                                <p>{'date dd/mm/yy'}</p>
                                                                <p>{'date dd/mm/yy'}</p>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className='claimContainer'>
                                                    <Button className='claimBtn'>Claim OwnerShip</Button>
                                                    <Button className='wishlist'> <img alt="" src={Heart} className='heartIcons' />Add to Wishlist</Button>
                                                </div>
                                                {/* </div> */}
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                                <Col xs={12} lg={12}>
                                    <div>
                                        <Row>
                                            <Col xs={12} lg={6}>
                                                <div>
                                                    <Row style={{ gap: 30 }}>
                                                        <Col xs={12} lg={12} style={{ padding: 20, borderRadius: 15 }} className='upperSection'>
                                                            <div style={{ background: 'linear-gradient(to right, #5ce1e6, #8c7ae6)', borderRadius: 7, padding: 10 }}>
                                                                <p className='description'>21 is Adele's second studio album. It was released in 2011 and has been a massive commercial success, selling over 31 million copies worldwide. The album won numerous awards, includi...</p>
                                                            </div>
                                                            <div style={{ marginTop: 25 }}>
                                                                <div className="artistInfo">
                                                                    <span className="keyLabel">{'Artist'}</span>
                                                                    <span className="valueLabel">{data?.vinyl?.description?.album_info?.artist ?? 'none'}</span>
                                                                </div>
                                                                <div className="artistInfo">
                                                                    <span className="keyLabel">{'Title'}</span>
                                                                    <span className="valueLabel">{data?.vinyl?.description?.album_info?.title ?? 'none'}</span>
                                                                </div>
                                                                <div className="artistInfo">
                                                                    <span className="keyLabel">{'Year'}</span>
                                                                    <span className="valueLabel">{data?.vinyl?.description?.album_info?.year ?? 'none'}</span>
                                                                </div>
                                                                <div className="artistInfo">
                                                                    <span className="keyLabel">{'Catalog Number'}</span>
                                                                    <span className="valueLabel">{data?.vinyl?.description?.album_info?.catalog_number ?? 'none'}</span>
                                                                </div>
                                                                <div className="artistInfo">
                                                                    <span className="keyLabel">{'Country of Origin'}</span>
                                                                    <span className="valueLabel">{data?.vinyl?.description?.album_info?.country_of_origin ?? 'none'}</span>
                                                                </div>
                                                                <div className="artistInfo">
                                                                    <span className="keyLabel">{'Genre'}</span>
                                                                    <span className="valueLabel">{data?.vinyl?.description?.album_info?.genre ?? 'none'}</span>
                                                                </div>
                                                                <div className="artistInfo">
                                                                    <span className="keyLabel">{'Size'}</span>
                                                                    <span className="valueLabel">{data?.vinyl?.description?.physical_attributes?.size ?? 'none'}</span>
                                                                </div>
                                                                <div className="artistInfo">
                                                                    <span className="keyLabel">{'Color'}</span>
                                                                    <span className="valueLabel">{data?.vinyl?.description?.physical_attributes?.color ?? 'none'}</span>
                                                                </div>
                                                                <div className="artistInfo">
                                                                    <span className="keyLabel">{'Over all Grade'}</span>
                                                                    <span className="valueLabel">{data?.vinyl?.description?.vinyl_condition?.overall_grade ?? 'none'}</span>
                                                                </div>
                                                                <div className="artistInfo">
                                                                    <span className="keyLabel">{'Edge Wear'}</span>
                                                                    <span className="valueLabel">{data?.vinyl?.description?.label_condition?.wear ?? 'none'}</span>
                                                                </div>
                                                                <div className="artistInfo">
                                                                    <span className="keyLabel">{'Seam Splits'}</span>
                                                                    <span className="valueLabel">{data?.vinyl?.description?.cover_condition?.seam_splits ?? 'none'}</span>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col xs={12} lg={12} style={{ padding: 20, borderRadius: 15 }} className='upperSection'>
                                                            <p className="imgTitle">Images</p>
                                                            <div style={{ display: 'flex', alignItems: 'center', gap: 10, flexWrap: 'wrap' }}>
                                                                {data?.images.map((image, index) => (
                                                                    <img key={index} alt="" src={image?.link} className='galleryImg' />
                                                                ))}
                                                                {/* <img alt="" src={VinylImg} className='galleryImg' />
                                                               
                                                                <img alt="" src={VinylImg} className='galleryImg' />
                                                                <img alt="" src={VinylImg} className='galleryImg' /> */}
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                            <Col xs={12} lg={6} className='rightAcordContainer'>
                                                {/* <div> */}
                                                <Row style={{ borderRadius: 15, minHeight: 467, padding: 20 }} className='upperSection'>
                                                    <Col xs={12} lg={12}>
                                                        {/* <div className="tabMenu">
                                                    <Button className="tabBtn">Tracklist</Button>
                                                    <Button className="tabBtn">Tracklist</Button>
                                                    <Button className="tabBtn">Tracklist</Button>
                                                </div> */}
                                                        <Accordion>
                                                            {tracks.map((track, index) => (
                                                                <TracklistItem key={index} track={track} index={index} />
                                                            ))}
                                                        </Accordion>
                                                    </Col>
                                                </Row>
                                                {/* </div> */}
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </>
            }
        </>
    );

}
